/* eslint-disable */

// Passive event listeners - for google page speed
jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') });
    },
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
    },
};

/****
*************
*************
  Off-Canvas Nav Functions
*************
*************
*****/
$('#desktopToggler').click(function() {
  $('.off-canvas-transform-menu').addClass('nav-flex');
  $('#siteVisibleArea').removeClass('site-visible-area-reverse');
  $('#siteVisibleAreaInner').removeClass('site-visible-area-inner-reverse');
  $('#siteVisibleArea').addClass('site-visible-area');
  $('#siteVisibleAreaInner').addClass('site-visible-area-inner');
  $('#canvasX').delay(500).fadeIn();
  $('#offCanvasMenu').delay(500).hide().animate({left:'auto', opacity:'show'}, 1000);
});

var mobileClicked = 0;

$('#mobileToggler').click(function() {
  if( mobileClicked == 0) {
    $('#siteVisibleArea').removeClass('site-visible-area-reverse-mobile');
    $('#siteVisibleAreaInner').removeClass('site-visible-area-inner-reverse-mobile');
    $('#siteVisibleArea').addClass('site-visible-area-mobile');
    $('#siteVisibleAreaInner').addClass('site-visible-area-inner-mobile');
    $('#offCanvasMobile').delay(500).hide().animate({left:'auto', opacity:'show'}, 1000);
    mobileClicked = 1;
  }
  else if(mobileClicked == 1) {
    $('#siteVisibleArea').addClass('site-visible-area-reverse-mobile');
    $('#siteVisibleAreaInner').addClass('site-visible-area-inner-reverse-mobile');
    $('#siteVisibleArea').removeClass('site-visible-area-mobile');
    $('#siteVisibleAreaInner').removeClass('site-visible-area-inner-mobile');
    $('#offCanvasMenu').hide();
    mobileClicked = 0;
  }
});

$('#canvasX').click(function() {
  $('.off-canvas-transform-menu').removeClass('nav-flex');
  $('#siteVisibleArea').addClass('site-visible-area-reverse');
  $('#siteVisibleAreaInner').addClass('site-visible-area-inner-reverse');
  $('#siteVisibleArea').removeClass('site-visible-area');
  $('#siteVisibleAreaInner').removeClass('site-visible-area-inner');
  $('#canvasX').hide();
  $('#offCanvasMenu').hide();
});

/****
*************
*************
  Document Ready Functions
*************
*************
*****/
jQuery(document).ready(function($) {
  //Transparent nav scrolling function
  //detect page top
  if($('.header-style-3-nav')[0]) {
      $(window).load(function() {
          if ($(window).scrollTop() >= .001) {
              $('.header-style-3-nav').addClass('scrolling');
          } else {
              $('.header-style-3-nav').removeClass('scrolling');
          }
      });
      $(window).scroll(function() {
          if ($(window).scrollTop() >= .001) {
              $('.header-style-3-nav').addClass('scrolling');
          } else {
              $('.header-style-3-nav').removeClass('scrolling');
          }
      });
  }

  //modal functions for team bios
  $('.open-bio').click(function() {
     $('.header-style-3-nav').css({'z-index':'2'});
     var bio_id = $(this).attr('bio_target');
     if($(this).hasClass('bio_999')) {
       $('#bio_999').addClass('d-block');
     } else {
       $('#'+bio_id).addClass('d-block');
     }
  });
  $('.modal-header button.close').click(function() {
      $('.modal').removeClass('d-block');
      $('.modal').addClass('d-none');
      $('.header-style-3-nav').css({'z-index':'10001'});
  });


  // to activate lightgallery
/*
  $(document).ready(function() {
      $('.add-lightgallery').lightGallery({
            selector: '.gallery-image'
        });
  });
*/

  /*
  ** WooCommerce Functions
  */
  
  // show mobile filter on button click
  $('#shopFilter').click(function() {
    $('.basebuild-shop-filter-in').slideToggle();
  });
  
  // review form on button click
  $('#addRevewButton').click(function() {
    $('#commentform').slideToggle();
  });
  
  /** navigation hover functions **/
  function toggleDropdown (e) {
    var d = $(this);
    var m = $($(this).find('.dropdown-menu')[0]);

    setTimeout(function(){
      var shouldOpen = e.type !== 'click' && d.is(':hover');
      m.toggleClass('show', shouldOpen);
      d.toggleClass('show', shouldOpen);
      $('[data-toggle="dropdown"]', d).attr('aria-expanded', shouldOpen);
    }, e.type === 'mouseleave' ? 50 : 0);
  }

  $(window).on('load resize', function() {
      $('body').on('mouseenter mouseleave','.dropdown',toggleDropdown);
  });
  
  ///////////////////////////////////////
  //
  // Change quantity input to + / - buttons
  //
  ///////////////////////////////////////
  jQuery( function( $ ) {
      if ( ! String.prototype.getDecimals ) {
          String.prototype.getDecimals = function() {
              var num = this,
                  match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
              if ( ! match ) {
                  return 0;
              }
              return Math.max( 0, ( match[1] ? match[1].length : 0 ) - ( match[2] ? +match[2] : 0 ) );
          }
      }
      // Quantity "plus" and "minus" buttons
      $( document.body ).on( 'click', '.plus, .minus', function() {
          var $qty        = $( this ).closest( '.quantity' ).find( '.qty'),
              currentVal  = parseFloat( $qty.val() ),
              max         = parseFloat( $qty.attr( 'max' ) ),
              min         = parseFloat( $qty.attr( 'min' ) ),
              step        = $qty.attr( 'step' );
          // Format values
          if ( ! currentVal || currentVal === '' || currentVal === 'NaN' ) currentVal = 0;
          if ( max === '' || max === 'NaN' ) max = '';
          if ( min === '' || min === 'NaN' ) min = 0;
          if ( step === 'any' || step === '' || step === undefined || parseFloat( step ) === 'NaN' ) step = 1;
          // Change the value
          if ( $( this ).is( '.plus' ) ) {
              if ( max && ( currentVal >= max ) ) {
                  $qty.val( max );
              } else {
                  $qty.val( ( currentVal + parseFloat( step )).toFixed( step.getDecimals() ) );
              }
          } else {
              if ( min && ( currentVal <= min ) ) {
                  $qty.val( min );
              } else if ( currentVal > 0 ) {
                  $qty.val( ( currentVal - parseFloat( step )).toFixed( step.getDecimals() ) );
              }
          }
          // Trigger change event
          $qty.trigger( 'change' );
      });
  });
}); // end document ready
